import React, { Component } from 'react';
import '../css/Login.css';

export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            usernameNotFound: false
        };
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ usernameNotFound: true });

        // Set a timeout to hide the error message after 3 seconds
        setTimeout(() => {
            this.setState({ usernameNotFound: false });
        }, 1500);
    }

    render() {
        return (
            <div className="login-container">
                <div className='login-wrapper'>
                    <h2>ModRev Organization</h2>
                    <div className="login-box">
                        <form onSubmit={this.handleSubmit}>
                            <div className="input-group">
                                <input type="text" id="username" name="username" required placeholder="Username" />
                            </div>
                            <div className="input-group">
                                <input type="password" id="password" name="password" required placeholder="Password" />
                            </div>
                            <button type="submit" className="login-button">Login</button>
                            {this.state.usernameNotFound && (
                                <div className="error-message fade-out">
                                    <br />
                                    <div>Username not found</div>
                                </div>
                            )}
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}