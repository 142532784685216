import React, { Component } from 'react';
import "../css/Payment.css";


export default class Payment extends Component {
    render() {
        return (
            <div className="embed">
                <div className='wrapper'>
                    <div className='innerWrapper'>
                        <h2>10431 Windlass Run Rd<br />Baltimore, MD 21220</h2>
                        <img className='image' src={require('../img/10431.webp')} alt="Payment" /><br />
                        <button className='button'
                            // onClick={() => window.location.href = "https://buy.stripe.com/5kAg0w45ufPQ8FOdQQ"} // application fee
                            onClick={() => window.location.href = "https://buy.stripe.com/fZe2bC00Bd7EbnidQR"} // rent+deposit
                        >
                            Submit Payment
                        </button>
                    </div>
                </div>
            </div >
        )
    }
}