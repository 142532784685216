import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// import Notfound from './sub/404';
import Jshinm from './sub/jshinm';
import Login from './sub/login';
import Payment from './sub/payment';
import Fee from './sub/fee';
import './css/App.css';
import JshinmPhotos from './sub/jshinmPhotos';


function App() {
  return (
    <Router>
      <Routes>
        {/* <Route path="/" element={<Notfound />} /> */}
        <Route path="/" element={<Login />} />
        <Route path="/jshinm" element={<Jshinm />} />
        <Route path="/photos" element={<JshinmPhotos />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/fee" element={<Fee />} />
      </Routes>
    </Router>
  );
}

export default App;
