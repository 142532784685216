import React, { Component } from 'react';
import "../css/Personal.css";

export default class JshinmPhotos extends Component {
    render() {
        return (
            <div class="embed">
                <iframe src="https://jshinm.netlify.app/Photos" title="embedded page" class='embed' />
            </div>
        )
    }
}